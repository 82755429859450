@use '@angular/material' as mat;
@import 'theme';
@import "toastr/toastr";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes($ad6-theme);
@import 'ad6';

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    flex: 0 0 auto;
    height: 4rem;
  }

  .main {
    flex: 1 1 auto;
    overflow: auto;
  }

  .footer {
    flex: 0 0 auto;
    height: 2rem;

  }
}

/**
  Function for replacing strings in an easy way
 */
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


/*
 * Add some quick padding and margin styles
 */
$types-list: padding margin;
$sizes-list: "0" "8px" "16px";
$sides-list: left right top bottom;

@each $current-type in $types-list {
  $type-character: str_slice($current-type, 1, 1);
  @each $current-size in $sizes-list {
    .#{$type-character}-#{str-replace($current-size, "px", "")} {
      #{$current-type}: #{$current-size};
    }

    @each $current-side in $sides-list {
      $side-character: str_slice($current-side, 1, 1);
      .#{$type-character}-#{$side-character}-#{str-replace($current-size, "px", "")} {
        #{$current-type}-#{$current-side}: #{$current-size};
      }
    }
  }
}
