@use '@angular/material' as mat;
@import "theme";

.mat-mdc-form-field {
  margin-right: .75rem;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-mdc-card {
  margin: .75rem;
}

.footer {
  background-color: mat.m2-get-color-from-palette($ad6-theme-primary);
}

.active {
  background-color: mat.m2-get-color-from-palette($ad6-theme-accent);
}

.ad6-edit-form {
  display: flex;
  flex-direction: column;
}

.ad6-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ad6-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  .ad6-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 8px;
    border-radius: 4px; 
  
    > * {
      padding: 0 8px;
    }  
  }

  .ad6-fab {
    position: absolute;
    margin-right: 2rem;
    right: 0;
  }

  
}

.profile-photo-thumb {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid #ccc;
  width: 75px;
  margin: 4px;
  min-height: 4rem;
}

.profile-photo-large {
  border-radius: 8px;
}

.mat-column-photo {
  flex: 0 0 100px;
}
